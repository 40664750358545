<div *ngFor="let toast of toasts$ | async" @verticalCollapse @triggerChild>
  <ngb-toast
    class="toast"
    [class]="toast.options.type"
    [autohide]="true"
    [animation]="true"
    [delay]="toast.options.delay"
    (hidden)="remove(toast)"
    @slideIn
    @slideOut
  >
    <div class="toast-content">
      <span>{{ toast.message | translate }}</span>

      <button class="button-icon" (click)="remove(toast)">
        <span class="icon close-white-icon"></span>
      </button>
    </div>
  </ngb-toast>
</div>
