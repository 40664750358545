import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const slideOut: AnimationTriggerMetadata = trigger('slideOut', [
  state(
    '*',
    style({
      transform: 'translateX(0)  scale(1)',
      opacity: 1,
    })
  ),
  state(
    'void',
    style({
      transform: 'translateX(100%) scale(.7)',
      opacity: 0,
    })
  ),
  transition(':leave', animate('.2s ease')),
]);
