import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Observable } from 'rxjs';

import { ToastsService } from '../../services/toasts/toasts.service';
import { ToastModel } from '../../models/toast/toast.model';
import { triggerChild } from '../../animations/trigger-child/trigger-child.animation';
import { slideIn } from '../../animations/slide-in/slide-in.animation';
import { slideOut } from '../../animations/slide-out/slide-out.animation';
import { verticalCollapse } from '../../animations/vertical-collapse/vertical-collapse.animation';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideIn, slideOut, verticalCollapse, triggerChild],
})
export class ToastsComponent {
  public toasts$: Observable<Array<ToastModel>>;

  constructor(private toastsService: ToastsService) {
    this.toasts$ = toastsService.getToasts();
  }

  public remove(toast: ToastModel): void {
    this.toastsService.remove(toast);
  }
}
